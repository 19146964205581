import * as React from "react";
import "./motionCard.scss";
import { motion } from "framer-motion";
// markup
const MotionCard = (props) => {
  return (
    <>
      <a href={props.urlTarget} target="_blank" rel="noreferrer">
        <motion.div
          initial={{ scale: 0.9 }}
          whileHover={{ scale: 1 }}
          transition={{ type: "spring", damping: 10, stiffness: 100 }}
          className="motion-card"
          class="box-container"
        >
          <div
            class="box"
            style={{
              background: "url(" + props.imageSrc + ")",
              backgroundSize: "cover",
            }}
          ></div>
          <div class="box stack-top">
            <h1>{props.children}</h1>
          </div>
        </motion.div>
      </a>
    </>
  );
};

export default MotionCard;
