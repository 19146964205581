import * as React from "react";
import Layout from "../components/layout/layout";
import ImageLink from "../components/motionCard/motionCard";
import { Container, Row, Col } from "react-bootstrap";
import "../general.scss";
import berliPng from "../images/berli_referenz.jpg";
import geritaPng from "../images/gerita_referenz.png";
import zueriseePng from "../images/zuerisee_referenz.png";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Netaro Webdesign</title>
        <meta
          name="description"
          content="Webseiten, Webshop und Design | Wählen Sie Netaro für Ihren digitalen Erfolg"
        />
        <link rel="canonical" href="http://netaro.ch/" />
      </Helmet>
      <Layout>
        <Container>
          <Row style={{ marginTop: "60px" }}>
            <h1>Laufende Projekte</h1>
          </Row>
          <Row>
            <Col md={4}>
              <ImageLink imageSrc={geritaPng}>
                Gerita <br /> vibras creativas
              </ImageLink>
            </Col>
            <Col md={4} />
            <Col md={4} />
          </Row>
          <Row style={{ marginTop: "60px" }}>
            <h1>Abgeschlossene Projekte</h1>
          </Row>
          <Row>
            <Col md={4}>
              <ImageLink
                imageSrc={berliPng}
                urlTarget="https://berli-treuhand.ch"
              >
                R. Berli <br /> TREUHAND AG
              </ImageLink>
            </Col>
            <Col md={4}>
              <ImageLink
                urlTarget="https://zueriseetransporte.ch"
                imageSrc={zueriseePng}
              >
                Zürisee <br /> Transporte GmbH
              </ImageLink>
            </Col>
            <Col md={4}></Col>
          </Row>
        </Container>
        <div
          style={{ marginTop: "151px" }}
          className="contact-section add-margin"
        >
          <Container>
            <Row>
              <Col md={4}>
                <p style={{ margin: "0px", fontSize: "30px" }}>
                  Interessiert?
                  <br /> Kontaktieren Sie Netaro
                </p>
              </Col>
              <Col md={4}>
                <p>
                  Xavier Webdesign
                  <br />
                  Glärnischstrasse 20
                  <br />
                  8820 Wädenswil
                </p>
              </Col>
              <Col md={4}>
                <div className="kontakt-link">
                  <a
                    className="primary-button-arrow"
                    href="mailto:hello@netaro.ch"
                  >
                    Hier geht es weiter <span id="arrow">&#8594;</span>
                  </a>{" "}
                  <br />
                  <br />{" "}
                  <a
                    style={{ marginTop: "900px" }}
                    className="primary-button-arrow"
                    href="tel:0041786713848"
                  >
                    Jetzt anrufen <span id="arrow">&#8594;</span>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;
